import { axiosPost, axiosGet,axiosPatch } from "./axiosFunctions";
import {checkLoginToken}                  from "./common";

const addSupportMaasterUrl    ="/api/homepage/addSupportMaster";
const addTestimonialUrl       ="/api/homepage/addTestimonial";
const getSupportMasterAllUrl  ="/api/homepage/getSupportMasterAll";
const getSupportAllUrl        ="/api/homepage/getSupportAll";
const getContactUsUrl         ="/api/homepage/getContactUs";
const getTestimonialAllUrl    = "/api/homepage/getTestimonialAll";
const updateSupportMasterUrl  ="/api/homepage/updateSupportMaster";
const updateTestimonialUrl    = "/api/homepage/updateTestimonial";

//Add

//AddTestimonial
export const addTestimonial = async (testimonial) => {
  const token = checkLoginToken();
     if (!token) return;
     const response = await axiosPost(
      addTestimonialUrl,
      { testimonial },
      { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

//Support Master
export const addSupportMaster = async (supportMaster) => {
    const token = checkLoginToken();
       if (!token) return;
       const response = await axiosPost(
        addSupportMaasterUrl,
        { supportMaster },
        { headers: { auth_token: `bearer ${token}` } }
    )
    return response;
  };

//Get

//Support
export const getSupportAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getSupportAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Support Master
export const getSupportMasterAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getSupportMasterAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//ContactUs
export const getContactUs = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getContactUsUrl,
    { headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};
//Testimonial
export const getTestimonialAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getTestimonialAllUrl,
    { headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};

//Update

//Support Master
export const  updateSupportMaster = async (supportMaster) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateSupportMasterUrl,
    { supportMaster },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};
//Testimonial
export const  updateTestimonial = async (testimonial) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateTestimonialUrl,
    { testimonial },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

