import React, { useState, useEffect }         from "react";
import { NavLink }                            from "react-router-dom";
import Swal                                   from "sweetalert2";
import Loading                                from "../global/Loading";
import SupportTable                           from "./SupportTable";
import { getSupportAll } from "../api/homepageAPI";
import "./supportmaster.css";


//Support Main component
const Support = (props) =>{

  const[loading,        setLoading]     = useState(false);
  const[refresh,        setRefresh]     = useState(false);
  const [AllQueries,    setAllQueries]  = useState([]);

  useEffect(() => {
    let ignore = false;
    const fetchSupportData = async()=>{
      try {
        setLoading(true);
        const response = await getSupportAll();
        if(response.length > 0) setAllQueries(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if (!ignore) fetchSupportData();
    return () => {
      ignore = true;
    };
  },[refresh])


  useEffect(()=>{
    props.title("Support","menuSupport");
  },[])

  const refetchQueries = () => setRefresh((r)=>(!r));

  
  return(
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/support">
                Support 
              </NavLink>
            </nav>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <SupportTable  
            AllQueries={AllQueries}
            refetchQueries= {()=>refetchQueries()}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Support;