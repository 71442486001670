import React, { useState, useEffect }                                                                                                  from "react";
import { NavLink }                                                                                                                     from "react-router-dom";
import $                                                                                                                               from "jquery";
import Loading                                                                                                                         from "../global/Loading";
import KnowledgeBaseCategoryTable                                                                                                      from "./KnowledgeBaseCategoryTable";
import * as helper                                                                                                                     from "../global/Helper";
import Swal                                                                                                                            from "sweetalert2";
import imageLogo                                                                                                                       from '../../assets/images/ic_image.png';
import ImageCropper                                                                                                                    from "../global/ImageCropper";
import {uploadFile}                                                                                                                    from  "../api/fileAPI";
import { addKnowledgeBaseCategories, getKnowledgeBaseCategoriesAll, updateKnowledgeBaseCategories,checkIfknowledgeBaseCategoryExists}  from "../api/applicationsAPI"; 
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

//Add Knowledge Base Category  Modal Starts ---->
export const AddKnowledgeBaseCategory = (props) => {

    const {
      show,
      onDismissModal,
      selectedCategory,
      refetchBaseCategories
    } = props;

    
    //Tutorial category States
    const [knowledgeBaseCategory,    setKnowledgeBaseCategory]   =   useState(selectedCategory ? selectedCategory: { Active: "Y" });
    const [bannerImageBlob,          setBannerImageBlob]         =   useState(null);
    const [showImageCropper,         setShowImageCropper]        =   useState(false);

    //TutorialCategoryDataHandler
    const KnowledgeBaseCategoryDataHandler = (key, value) => {
        setKnowledgeBaseCategory((data) => {
        return { ...data, [key]: value };
      });
    };


    // add knowledge base category handler
    const addKnowledgeBaseCategoryHandler = async (e) => {
      e.preventDefault();
  
      var form = $("#add-knowledgebasecategory-form");
      form.validate().destroy();
  
      form.validate({
        rules: {
          CategoryName: {
            required: true,
          },
          Details: {
            required: true,
          },
          Index: {
            required: true,
          },
        },
      });

      
      if (!form.valid()) {
        return;
      }
  
      //knowledge base category data save code goes here
      try {
        if(!selectedCategory || (selectedCategory && selectedCategory.CategoryName !== knowledgeBaseCategory.CategoryName)){
          const response = await checkIfknowledgeBaseCategoryExists(knowledgeBaseCategory.CategoryName);
          if (response) {
            return Swal.fire({
              icon                  : "warning",                    
              titleText             : " Knowledgebase Category Already exists !",
              buttonsStyling        : false,
              confirmButtonClass    : "btn btn-brand",
            })
          }
        }  

        if(!bannerImageBlob && !knowledgeBaseCategory.Image){
          return Swal.fire({
            icon                : "warning",                    
            titleText           : " Select an image !",
            buttonsStyling      : false,
            confirmButtonClass  : "btn btn-brand",
          })
        }

        helper.StartProcessing($("#save-btn"));
        let fileName ;
        if(bannerImageBlob) fileName = await uploadFile(bannerImageBlob,knowledgeBaseCategory.CategoryName.replace(/\s/g, "-").toLowerCase());
        if(fileName) knowledgeBaseCategory.Image = fileName;
        if (selectedCategory) {

          if(!bannerImageBlob && knowledgeBaseCategory.Image) knowledgeBaseCategory.Image = knowledgeBaseCategory.Image.split("WebsiteImages/")[1];
          await updateKnowledgeBaseCategories(knowledgeBaseCategory);
          Swal.fire({
            icon                      : "success",
            titleText                 : "knowledge base category updated Successfully",
            showConfirmButton         : false,
            timer                     : 1500,
            toast                     :true,
            position                  :'bottom-end'
          });
        } else {
          await addKnowledgeBaseCategories(knowledgeBaseCategory);
          Swal.fire({
            icon                      : "success",
            titleText                 : "knowledge base category Added Successfully",
            showConfirmButton         : false,
            timer                     : 1500,
          });
        }
        refetchBaseCategories();
        $("#add-knowledgebasecategory-modal").modal("hide");
      } catch (err) {
        Swal.fire({
          icon                      : "error",
          titleText                 : "Error!",
          text                      : err.message,
          buttonsStyling            : false,
          confirmButtonClass        : "btn btn-brand",
        });
      }
    };
  
    useEffect(() => {
      $("#add-knowledgebasecategory-modal").modal({
        backdrop: "static",
      });
  
      $("#add-knowledgebasecategory-modal").on("hidden.bs.modal", function () {
        $(".modal").hasClass("show") && $("body").addClass("modal-open");
        onDismissModal();
      });
      $("#add-knowledgebasecategory-modal").modal("toggle");
    }, [show]);

    const deleteImageHandler = () => {
      setBannerImageBlob(null);
     if(knowledgeBaseCategory.Image){
       delete knowledgeBaseCategory.Image;
       setKnowledgeBaseCategory({...knowledgeBaseCategory});
      }
    }

    const showImageCropperHandler = () => { 
      knowledgeBaseCategory.CategoryName 
      ? setShowImageCropper(true)
      : Swal.fire(
        { 
          icon: "warning", 
          titleText: "Please Enter Category Name"
        }
      ) 
    }
  
    return (
      <>
        <div
          className="modal fade"
          id="add-knowledgebasecategory-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {`${selectedCategory ? "Edit" : "Add"} Knowledge Base Category`}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                </button>
              </div>
              <div className="modal-body">
                <div className="author-container">
                  <form id="add-knowledgebasecategory-form">
                    <div className="row">
                      <div className="col-12 ">
                        <input
                          type="text"
                          className="form-control"
                          name="CategoryName"
                          id="CategoryName"
                          placeholder="Category Name"
                          value={knowledgeBaseCategory.CategoryName || ""}
                          onChange={({ target }) =>
                            KnowledgeBaseCategoryDataHandler("CategoryName", helper.titleCase(target.value))
                          }
                        />
                      </div>
                    
                    </div>
                    <div className="row margin-t20">
                      <div className="col-12">
                        <textarea
                          className="form-control"
                          name="Details"
                          id="Details"
                          row="2"
                          placeholder="Details"
                          value={knowledgeBaseCategory.Details || ""}
                          onChange={({ target }) =>
                          KnowledgeBaseCategoryDataHandler("Details", target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row margin-t20">
                      <div className="col-lg-5 col-md-6  py-3">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm form-control"
                          onClick={showImageCropperHandler}
                        >
                          <i className="fa fa-upload mr-2"></i> 
                          {`${bannerImageBlob ||(selectedCategory && selectedCategory.Image) ? "Update" : "Upload"} Image (200 W x 200 H pixels)`}
                        </button>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={knowledgeBaseCategory.Index || ""}
                          onChange={({ target }) =>
                          KnowledgeBaseCategoryDataHandler("Index", target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 py-3">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  knowledgeBaseCategory.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  knowledgeBaseCategory.Active === "N"
                                    ? KnowledgeBaseCategoryDataHandler("Active", "Y")
                                    : KnowledgeBaseCategoryDataHandler("Active", "N");
                                }}
                              />
                              <span>{knowledgeBaseCategory.Active}</span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                    {( bannerImageBlob || (selectedCategory && selectedCategory.Image) ) &&
                        <div className="row">
                          <div className="col-12">
                            <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                              <span className="kt-notification__item">
                                <div className="kt-notification__item-icon margin-r10" >
                                  <img height="30px" width="30px" src={imageLogo} />
                                </div>
                                <div className="kt-notification__item-details" >
                                  <div className="kt-notification__item-title kt-font-bold">
                                    {bannerImageBlob?
                                      <a href={URL.createObjectURL(bannerImageBlob)} target="_blank" rel="noopener noreferrer">
                                        {`${bannerImageBlob.name}.${bannerImageBlob.type.split('/')[1]}`}
                                      </a>
                                      :
                                      <a href={knowledgeBaseCategory.Image} target="_blank" rel="noopener noreferrer">
                                        {knowledgeBaseCategory.Image.split("WebsiteImages/")[1]}
                                      </a>
                                    }
                                  </div>
                                </div>
                                <button 
                                  type="button"                    
                                  onClick={()=>deleteImageHandler()}
                                  className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                  <i className="flaticon2-rubbish-bin-delete-button"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary margin-0 margin-r5"
                  data-dismiss="modal"
                >
                  Close
                </button>


                <button
                  id="update-btn"
                  onClick={addKnowledgeBaseCategoryHandler}
                  type="button"
                  className="btn btn-brand margin-0">
                  { selectedCategory  ? "Update" : "Save" }
                </button>
              </div>
            </div>
          </div>
        </div>

        {showImageCropper && (
          <ImageCropper
            show={showImageCropper}
            onDismissModal={() => setShowImageCropper(false)}
            fileAlias = {knowledgeBaseCategory.CategoryName && knowledgeBaseCategory.CategoryName.replace(/\s/g, "-").toLowerCase()}
            imageRatio={{width:200, height:200}}
            DataHandler={(value)=>setBannerImageBlob(value)}
          />
        )}
      </>
    );
  };
  //knowledge base category  Modal Ends ---->
  
  
  // Main  Knowledge Base Category Component
  const KnowledgeBaseCategory = (props) => {
  
    const [loading,                           setLoading]                        =  useState(false);
    const [allBaseCategories,                 setallBaseCategories]              =  useState([]);
    const [selectedCategory,                  setSelectedCategory]               =  useState(null);
    const [showAddKnowledgeBaseCategory,      setshowAddKnowledgeBaseCategory]   =  useState(false);
    const [refresh,                           setRefresh]                        =  useState(false);

    useEffect(() => {
      if (!showAddKnowledgeBaseCategory) setSelectedCategory(null);
    }, [showAddKnowledgeBaseCategory]);


    useEffect(() => {
      let ignore = false;
      const fetchKnowledgeCategoriesAll = async () => {
        try {
          setLoading(true);
          const response = await getKnowledgeBaseCategoriesAll();
          setallBaseCategories(response);
          setLoading(false);
        } catch (error) {
          Swal.fire({
            icon                  : "error",
            titleText             : "Error!",
            text                  : error.message,
            buttonsStyling        : false,
            confirmButtonClass    : "btn btn-brand",
          });
        }
      };
      if (!ignore) fetchKnowledgeCategoriesAll();
      return () => {
        ignore = true;
      };
    }, [refresh]);

    const editKnowledgeCategoryHandler = (knowledgeBaseCategory) =>{
      setSelectedCategory(knowledgeBaseCategory);
      setshowAddKnowledgeBaseCategory(true);
    }

    const toggleKnowledgeCategorySwitch =  async (knowledgeBaseCategory) => {
      let a = knowledgeBaseCategory;
      if (a.Active === "Y") {
        a.Active = "N"
      } else {
        a.Active = "Y"
      }
      await updateKnowledgeBaseCategories(a);
    }

    
   const refetchBaseCategories = () => setRefresh((r) => !r);

    useEffect(() => {
      props.title("Knowledge Base Category", "menuKnowledgeBaseCategory");
    }, []);

    
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
            <div className="kt-portlet__head-label">
              <nav className="file-nav navbar navbar-top">
                <NavLink activeClassName="top-nav-active" to="/knowledge-base-category">
                  Knowledge Base Category
                </NavLink>
              </nav>
            </div>
  
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <div className="kt-portlet__head-actions">
                  <button
                    className="btn btn-brand btn-icon-sm text-white"
                    onClick={() => setshowAddKnowledgeBaseCategory(true)}>
                    <i className="la la-plus"></i>
                    New Category
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <div className="kt-portlet__body kt-portlet__body--fit">
            {loading ? (
              <Loading />
            ) : (
              <KnowledgeBaseCategoryTable
                allBaseCategories={allBaseCategories}
                editKnowledgeCategoryHandler={editKnowledgeCategoryHandler}
                toggleKnowledgeCategorySwitch={toggleKnowledgeCategorySwitch}
              />
            )}
          </div>
        </div>
  
        {showAddKnowledgeBaseCategory && (
          <AddKnowledgeBaseCategory
            show={showAddKnowledgeBaseCategory}
            onDismissModal={() => setshowAddKnowledgeBaseCategory(false)}
            selectedCategory={selectedCategory}
            refetchBaseCategories={refetchBaseCategories}
          />
        )}
      </div>
    );
  };
  
  export default KnowledgeBaseCategory;
