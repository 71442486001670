import React, { useState, useEffect }                                                                                             from "react";
import { NavLink }                                                                                                                from "react-router-dom";
import Swal                                                                                                                       from "sweetalert2";
import Select                                                                                                                     from "react-select";
import $                                                                                                                          from "jquery";
import * as helper                                                                                                                from "../global/Helper";
import Loading                                                                                                                    from "../global/Loading";
import KnowledgeBaseTable                                                                                                         from "./KnowledgeBaseTable";
import { addKnowledgeBase, updateKnowledgeBase, getKnowledgeBaseAll, getKnowledgeBaseCategoriesAll,checkIfknowledgeBaseExists  }  from "../api/applicationsAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";



//Add Knowledge Base Modal Starts ---->
export const AddKnowledgeBase = (props) => {
    
  const {
    show,
    onDismissModal,
    selectedBase,
    refetchKnowledgeBase
  } = props;

  //Knowledge Base States
  const [allKnowledgeBase,     setAllKnowledgeBase]   =  useState([]);
  const [KnowledgeBaseData,    setKnowledgeBaseData]  =  useState(selectedBase ? selectedBase : { Active: "Y" });

  useEffect(() => { 
    let ignore = false;
    const fetchKnowledgeBase = async () => {
      const response = await getKnowledgeBaseCategoriesAll();
      if(response.length > 0){
        response.map(c => {if (c.Active === "Y"){setAllKnowledgeBase(d=>[...d,{label:c.CategoryName,value:c}])}})
      }
    }
    if(!ignore) fetchKnowledgeBase()
    return () => {
      ignore = true;
    }
  },[])

  
  useEffect(() => {
    if(KnowledgeBaseData.Content) window.setEditorValue("#editorContent",KnowledgeBaseData.Content);
    if (KnowledgeBaseData.CategoryID) $("#CategoryName-error").hide();
    if (KnowledgeBaseData.Title) {$("#Alias-error").hide()}else{$("#Alias-error").show()}
  }, [KnowledgeBaseData]);

  
  //KnowledgeBaseDataHandler
  const KnowledgeBaseDataHandler = (key, value) => {
    setKnowledgeBaseData((data) => {
      return { ...data, [key]: value };
    });
  };

  //add Knowledge Base Handler
  const addKnowledgeBaseHandler =  async (e) => {
    e.preventDefault();

    //get ckEditorData
    const Content = window.getEditorValue("#editorContent");

    var form = $("#add-knowledgebase-form");
    form.validate().destroy();

    form.validate({
      rules: {
        Title: {
          required: true,
        },
        Alias: {
          required: true,
        },
        Description: {
          required: true,
        },
        Index: {
          required: true,
        },
        CategoryName: {
          required: true,
        },
      }
    });

    if (!form.valid()) {
      return;
    }

    if (!Content) {
      return Swal.fire({
        icon: "error",
        title: " Knowledge Base Content is required !",
      });
      
    } 

    // Blog data save code goes here
    try {
      if(!selectedBase || (selectedBase && selectedBase.Alias !== KnowledgeBaseData.Alias)){
          const response = await checkIfknowledgeBaseExists(KnowledgeBaseData.Alias);
          if (response) {
            return Swal.fire({
              icon                : "warning",                    
              titleText           : " Knowledgebase Already exists !",
              buttonsStyling      : false,
              confirmButtonClass  : "btn btn-brand",
          })
        }
      }  

      helper.StartProcessing($("#save-btn"));
  
      if (selectedBase) {

        await updateKnowledgeBase({...KnowledgeBaseData,Content});
        Swal.fire({
          icon                        : "success",
          titleText                   : "Knowledge Base Updated Successfully",
          showConfirmButton           :  false,
          timer                       :  1500,
          toast                       :  true,
          position                    : 'bottom-end'
        });
      } else {
        await addKnowledgeBase({...KnowledgeBaseData,Content});
        
        $("#add-knowledgebase-modal").modal("hide");
        Swal.fire({
          icon                    : "success",
          titleText               : "Knowledge Base Added Successfully",
          showConfirmButton       :  false,
          timer                   :  1500,
        });
      }
      refetchKnowledgeBase();
      $("#add-knowledgebase-modal").modal("hide");
    } catch (err) {
      Swal.fire({
        icon                        : "error",
        titleText                   : "Error!",
        text                        :  err.message,
        buttonsStyling              :  false,
        confirmButtonClass          : "btn btn-brand",
      });
    }
  };


  //Modal Opener
  useEffect(() => {

    $("#add-knowledgebase-modal").modal({
      backdrop: "static",
    });

    window.initCkeditor("#editorContent");

    $("#add-knowledgebase-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      window.removeCkeditor("editorContent");
      onDismissModal();
    });
    $("#add-knowledgebase-modal").modal("toggle");
  }, [show]);


  return (
    <>
      <div
        className="modal fade"
        id="add-knowledgebase-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
         {/* {header}  */}
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
              {`${selectedBase ? "Edit" : "Add"} Knowledge Base`}{" "}
                </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
              </button>
            </div>

            {/* {body} */}
            <div className="modal-body">
              <div className="author-container">
                <form id="add-knowledgebase-form">
                  {/* Name / Alias */}
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        name="Title"
                        id="Title"
                        placeholder="Title"
                        value={KnowledgeBaseData.Title || ""}
                        onChange={({ target }) => {
                            KnowledgeBaseDataHandler("Title", helper.titleCase(target.value));

                            KnowledgeBaseDataHandler(
                            "Alias",
                            target.value.replace(/\s/g, "-").toLowerCase()
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        name="Alias"
                        id="Alias"
                        placeholder="Alias"
                        value={KnowledgeBaseData.Alias || ""}
                        onChange={({ target }) =>
                        KnowledgeBaseDataHandler(
                            "Alias",
                            target.value.replace(/\s/g, "-").toLowerCase()
                          )
                        }
                      />
                    </div>
                  </div>
                  {/* Description */}
                  <div className="row margin-t20">
                    <div className="col-12">
                      <textarea
                        className="form-control"
                        name="Description"
                        id="Description"
                        row="2"
                        placeholder="Description"
                        value={KnowledgeBaseData.Description || ""}
                        onChange={({ target }) =>
                        KnowledgeBaseDataHandler("Description", target.value)
                        }
                      />
                    </div>
                  </div>

                  {/* Category Type / Index / Active */}
                  <div className="row margin-t10">
                      <div className="col-lg-6 col-md-4 pt-2  padding-t10">
                        <Select
                          options={allKnowledgeBase}
                          value={KnowledgeBaseData && allKnowledgeBase.find(k => k.value.ID === KnowledgeBaseData.CategoryID)}
                          onChange={(event)=>KnowledgeBaseDataHandler('CategoryID',event.value.ID)}
                          placeholder="Select Category"
                          id="CategoryName"
                          name="CategoryName"
                        />
                      </div>
                    <div className="col-lg-4 col-md-4 ">
                      <input
                        type="number"
                        className="form-control"
                        name="Index"
                        id="Index"
                        placeholder="Index"
                        value={KnowledgeBaseData.Index || ""}
                        onChange={({ target }) =>
                        KnowledgeBaseDataHandler ("Index", parseInt(target.value))
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-md-4 pt-3">
                      <div className="d-flex align-item-center">
                        <span className="pt-2 mr-3">Active</span>
                        <span className="kt-switch kt-switch--icon">
                          <label>
                            <input
                              type="checkbox"
                              name="Active"
                              defaultChecked={
                                KnowledgeBaseData.Active === "Y" ? "checked" : ""
                              }
                              onChange={() => {
                                KnowledgeBaseData.Active === "N"
                                  ? KnowledgeBaseDataHandler("Active", "Y")
                                  : KnowledgeBaseDataHandler("Active", "N");
                              }}
                            />
                            <span> </span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
            
                  {/* Content- CkEditor */}
                  <div className="row margin-t20">
                    <div className="col-12">
                      <textarea name="editorContent" id="editorContent" />
                    </div>
                  </div>   
                </form>
              </div>
            </div>

            {/* {footer} */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal"
              >
                Close
              </button>

              <button
                id="save-btn"
                onClick={addKnowledgeBaseHandler}
                type="button"
                className="btn btn-brand margin-0"
              >
              { selectedBase ? "Update" : "Save" }
              </button>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};
//Add KnowledgeBase Modal Ends ---->

// Main Knowledge Base Component
const KnowledgeBase = (props) => {
  
  const [loading,                   setLoading]               =  useState(false);
  const [allKnowledgeBases,         setAllKnowledgeBases]     =  useState([]);
  const [showAddKnowledgeBase,      setShowAddKnowledgeBase]  =  useState(false);
  const [selectedBase,              setSelectedBase]          =  useState(null);
  const [refresh,                   setRefresh]               =  useState (false);

  
  useEffect(() => {
    let ignore = false;
    const fetchKnowledgeBaseAll = async () => {
      try {
        setLoading(true);
        const response = await getKnowledgeBaseAll();
        setAllKnowledgeBases(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          titleText: "Error!",
          text: error.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchKnowledgeBaseAll();
    return () => {
      ignore = true;
    };
  }, [refresh]);
  

  useEffect(() => {
    if (!showAddKnowledgeBase) setSelectedBase(null);
  }, [showAddKnowledgeBase]);



  const editKnowledgeBaseHandler = (KnowledgeBaseData) => {
    setSelectedBase(KnowledgeBaseData);
    setShowAddKnowledgeBase(true);
  }

  const toggleKnowledgeBaseStatus =  async (KnowledgeBaseData) => {
    let a = KnowledgeBaseData;
    if (a.Active === "Y") {
      a.Active = "N"
    } else {
      a.Active = "Y"
    }
    await updateKnowledgeBase(a);
  }

  const refetchKnowledgeBase = () => setRefresh((r) => !r);

  useEffect(() => {
    props.title("Knowledge Base", "menuKnowledgeBase");
  }, []);
  

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/knowledge-base">
              Knowledge Base
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setShowAddKnowledgeBase(true)}>
                  <i className="la la-plus"></i>
                  New Knowledge Base
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <KnowledgeBaseTable
              allKnowledgeBases = {allKnowledgeBases}
              editKnowledgeBaseHandler = {editKnowledgeBaseHandler}
              toggleKnowledgeBaseStatus = {(a)=>toggleKnowledgeBaseStatus(a)}
            />
          )}
        </div>
      </div>

      {showAddKnowledgeBase && (
        <AddKnowledgeBase
          show={showAddKnowledgeBase}
          onDismissModal={() => setShowAddKnowledgeBase(false)}
          selectedBase = {selectedBase}
          refetchKnowledgeBase = {() => refetchKnowledgeBase()}
        />
      )}
    </div>
  );
};

export default KnowledgeBase;
