import React, { useState, useEffect }                                                               from "react";
import { NavLink }                                                                                  from "react-router-dom";
import $                                                                                            from "jquery";
import Loading                                                                                      from "../global/Loading";
import FaqTable                                                                                     from "./FaqTable";
import * as helper                                                                                  from "../global/Helper";
import Swal                                                                                         from "sweetalert2";
import { addFAQ, updateFAQ, getFAQ }                                                                from "../api/applicationsAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal"; 
import "../../assets/scripts/jquery-validation.init";

//Add FAQ Modal Starts ---->
export const AddFaq = (props) => {

  const {
    show,
    onDismissModal,
    selectedFAQ,refetchFAQ
  } = props;

  const [btnLoading, setBtnLoading] = useState(false);
  const [Faq,        setFaq]        = useState(selectedFAQ ? selectedFAQ:{ Active: "Y" });

  //FAQDataHandler
  const faqDataHandler = (key, value) => {
    setFaq((data) => {
      return { ...data, [key]: value };
    });
  };


  // add video tutorial handler
  const addFAQHandler = async (e) => {
    e.preventDefault();

    var form = $("#add-faq-form");
    form.validate().destroy();

    form.validate({
      rules: {
        Question: {
          required : true,  
        },
        Answer: {
            required: true,
        },  
        Index: {
          required: true,
        },
      },
    });
    if (!form.valid()) {
      return;
    }

    try {
      helper.StartProcessing($("#save-btn"));
      if (selectedFAQ) {
        //update api goes here
        updateFAQ(Faq)
        Swal.fire({
          icon                  : "success",
          titleText             : "FAQ Updated Successfully",
          showConfirmButton     : false,
          timer                 : 1500,
          toast                 :true,
          position              :'bottom-end'
        });
      } else {
       
        //add api goes here
        addFAQ(Faq)
        $("#add-faq-modal").modal("hide");
        Swal.fire({
          icon                : "success",
          titleText           : "FAQ Added Successfully",
          showConfirmButton   : false,
          timer               : 1500,
        });
      }
      refetchFAQ();
      $("#add-faq-modal").modal("hide");
    } catch (err) {
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }
  };



  useEffect(() => {
    $("#add-faq-modal").modal({
      backdrop: "static",
    });

    $("#add-faq-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });
    $("#add-faq-modal").modal("toggle");
  }, [show]);



  return (
    <div
      className="modal fade"
      id="add-faq-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {`${selectedFAQ ? "Edit" : "Add"} FAQ`}{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={btnLoading}>
            </button>
          </div>
            <div className="modal-body">
                <form id="add-faq-form">
                    <div className="row">
                        <div className="col-lg-6 col-md-4">
                            <input
                                type="text"
                                className="form-control"
                                name="Question"
                                id="Question"
                                placeholder="Question"
                                value={Faq.Question || ""}
                                onChange={({ target }) =>
                                    faqDataHandler("Question", helper.titleCase(target.value))
                                }
                            />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <input
                                type="number"
                                className="form-control"
                                name="Index"
                                id="Index"
                                placeholder="Index"
                                value={Faq.Index || ""}
                                onChange={({ target }) =>
                                    faqDataHandler("Index", target.value)
                                }
                            />
                        </div>
                        <div className="col-lg-2 col-md-4">
                            <div className="">
                            <div className="d-flex align-item-center">
                            <span className="pt-2 mr-3">Active</span>
                            <span className="kt-switch kt-switch--icon">
                                <label>
                                <input
                                    className=""
                                    type="checkbox"
                                    name=""
                                    defaultChecked={
                                    Faq.Active === "Y" ? "checked" : ""
                                    }
                                    onChange={() => {
                                    Faq.Active === "N"
                                        ? faqDataHandler("Active", "Y")
                                        : faqDataHandler("Active", "N");
                                    }}
                                />
                                <span> </span>
                                </label>
                            </span>
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <textarea 
                                type="text"
                                className="form-control"
                                name="Answer"
                                id="Answer"
                                placeholder="Answer"
                                rows='2'
                                value={Faq.Answer || ""}
                                onChange={({target})=>faqDataHandler('Answer',target.value)}
                            />
                        </div>
                    </div> 
                </form> 
            </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal"
              disabled={btnLoading}>
              Close
            </button>

            <button
              id="save-btn"
              onClick={addFAQHandler}
              type="button"
              className= "btn btn-brand margin-0">
              {selectedFAQ ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



// Main FAQ Component
const Faq = (props) => {
  
  const [loading,                                   setLoading] = useState(false);
  const [allFAQs,                                   setallFAQs] = useState([]);
  const [showAddFAQ,                             setshowAddFAQ] = useState(false);
  const [selectedFAQ,                           setselectedFAQ] = useState(null);
  const [refresh,                                   setRefresh] = useState(false);

  useEffect(() => {
    if (!showAddFAQ) setselectedFAQ(null);
  }, [showAddFAQ]);

  useEffect(() => {
    let ignore = false;
    const fetchFAQ = async () => {
      try {
        setLoading(true);
        const response = await getFAQ();
        setallFAQs(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                    : "error",
          titleText               : "Error!",
          text                    : error.message,
          buttonsStyling          : false,
          confirmButtonClass      : "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchFAQ();
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const editFAQHandler = (FAQ) => {
    setselectedFAQ(FAQ);
    setshowAddFAQ(true);
  }

  const toggleFAQStatus =  async (FAQ) => {
    let a = FAQ;
    if (a.Active === "Y") {
      a.Active = "N"
    } else {
      a.Active = "Y"
    }
    await updateFAQ(a);
  }

  const refetchFAQ = () => setRefresh((r) => !r);

  useEffect(() => {
    props.title(" Frequently Asked Question ", "menuFAQ");
  }, []);

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/faq">
                FAQ's
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setshowAddFAQ(true)}>
                  <i className="la la-plus"></i>
                  New FAQ
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <FaqTable
              allFAQs = {allFAQs}
              editFAQHandler = {editFAQHandler}
              toggleFAQStatus = {toggleFAQStatus}
            />
          )}
        </div>
      </div>

      {showAddFAQ && (
        <AddFaq
          show={showAddFAQ}
          onDismissModal={() => setshowAddFAQ(false)}
          selectedFAQ = {selectedFAQ}
          refetchFAQ={() => refetchFAQ()}
        />
      )}
    </div>
  );
};

export default Faq;
