import React, { useState, useEffect }                                 from "react";
import { NavLink }                                                    from "react-router-dom";
import $                                                              from "jquery";
import Loading                                                        from "../global/Loading";
import SupportMasterTable                                             from "./SupportMasterTable";
import * as helper                                                    from "../global/Helper";
import Swal                                                           from "sweetalert2";
import { addSupportMaster, getSupportMasterAll, updateSupportMaster}  from "../api/homepageAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

//Add Support Master Modal Starts ---->
export const AddSupportMaster = (props) => {

  const {
    show,
    onDismissModal,
    selectedQuery,
    refetchSupportMaster
  } = props;

  
  //Support Master States
  const [btnLoading,          setBtnLoading]     = useState(false);
  const [supportMaster,       setSupportMaster]  = useState(selectedQuery ? selectedQuery : { Active: "Y" });

  //SupportMasterDataHandler
  const SupportMasterDataHandler = (key, value) => {
    setSupportMaster((data) => {
      return { ...data, [key]: value };
    });
  };

  const addSupportMasterHandler = async (e) => {
    e.preventDefault();

    var form = $("#add-supportmaster-form");
    form.validate().destroy();

    form.validate({
      rules: {
        Query: {
          required: true,
        },
        Index: {
          required: true,
        },
      },
    });
    if (!form.valid()) {
      if ($("#Query-error").text() !== "") {
        $("#Query").addClass("is-invalid");
      }
      if ($("#Index-error").text() !== "") {
        $("#Index").addClass("is-invalid");
      }
      return;
    }

    //support master data save code goes here
    try {
      helper.StartProcessing($("#save-btn"));
      if (selectedQuery) {
        await updateSupportMaster(supportMaster);
        Swal.fire({
          icon                  : "success",
          titleText             : "Support Tag updated Successfully",
          showConfirmButton     : false,
          timer                 : 1500,
          toast                 : true,
          position              :'bottom-end'
        });
      } else {
        await addSupportMaster(supportMaster);
        Swal.fire({
          icon                 : "success",
          titleText            : "Support Tag Added Successfully",
          showConfirmButton    : false,
          timer                : 1500,
          toast                : true,
          position             :'bottom-end'
        });
      }
      refetchSupportMaster();
      $("#add-supportmaster-modal").modal("hide");
      Swal.fire({
        icon                : "success",
        titleText           : "Support Tag Added Successfully",
        showConfirmButton   : false,
        timer               : 1500,
        toast               : true,
        position            :'bottom-end'
      });
    } catch (err) {
      Swal.fire({
        icon                : "error",
        titleText           : "Error!",
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : "btn btn-brand",
      });
    }
  };

  useEffect(() => {
    $("#add-supportmaster-modal").modal({
      backdrop: "static",
    });

    $("#add-supportmaster-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });
    $("#add-supportmaster-modal").modal("toggle");
  }, [show]);



  return (
    <div
      className="modal fade"
      id="add-supportmaster-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {`${selectedQuery ? "Edit" : "Add"} Support Master`}{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={btnLoading}>
            </button>
          </div>
          <div className="modal-body">
            <div className="author-container">
              <form id="add-supportmaster-form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="Query"
                          id="Query"
                          placeholder="Query"
                          value={supportMaster.Query || ""}
                          onChange={({ target }) =>
                            SupportMasterDataHandler("Query", helper.titleCase(target.value))
                          }
                        />
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={supportMaster.Index || ""}
                          onChange={({ target }) =>
                            SupportMasterDataHandler("Index", target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 py-3">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  supportMaster.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                    supportMaster.Active === "N"
                                    ? SupportMasterDataHandler("Active", "Y")
                                    : SupportMasterDataHandler("Active", "N");
                                }}
                              />
                              <span> </span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal"
              disabled={btnLoading}>
              Close
            </button>

            <button
              id="save-btn"
              onClick={addSupportMasterHandler}
              type = "button"
              className = "btn btn-brand margin-0">
              { selectedQuery ? "Update" : "Save" }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//Add Support Master Modal Ends ---->

// Main Support Master Component
const SupportMaster = (props) => {
  
  const [loading,                 setLoading]               = useState(false);
  const [allSupportQuery,         setAllSupportQuery]       = useState([]);
  const [showAddSupportMaster,    setShowAddSupportMaster]  = useState(false);
  const [selectedQuery,           setSelectedQuery]         = useState(false);
  const [refresh,                 setRefresh]               =  useState(false);

  useEffect(() => {
    let ignore = false;
    const fetchSupportQueriesAll = async () => {
      try {
        setLoading(true);
        const response = await getSupportMasterAll();
        setAllSupportQuery(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                    : "error",
          titleText               : "Error!",
          text                    : error.message,
          buttonsStyling          : false,
          confirmButtonClass      : "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchSupportQueriesAll();
    return () => {
      ignore = true;
    };
  }, [refresh]);
  
  useEffect(() => {
    if (!showAddSupportMaster) setSelectedQuery(null);
  }, [showAddSupportMaster]);

  const editQueryHandler = (supportMaster) => {
    setSelectedQuery(supportMaster);
    setShowAddSupportMaster(true);
  }

  const toggleSupportMasterStatus =  async (supportMaster) => {
    let a = supportMaster;
    if (a.Active === "Y") {
      a.Active = "N"
    } else {
      a.Active = "Y"
    }
    await updateSupportMaster(a);
  }

  const refetchSupportMaster = () => setRefresh((r) => !r);

  useEffect(() => {
    props.title("Support Master", "menuSupportMaster");
  }, []);


  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/support-master">
                Support Master
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setShowAddSupportMaster(true)}>
                  <i className="la la-plus"></i>
                  New Master
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <SupportMasterTable
              allSupportQuery = {allSupportQuery}
              editQueryHandler = {editQueryHandler}
              toggleSupportMasterStatus = {toggleSupportMasterStatus}
            />
          )}
        </div>
      </div>

      {showAddSupportMaster && (
        <AddSupportMaster
          show={showAddSupportMaster}
          onDismissModal={() => setShowAddSupportMaster(false)}
          selectedQuery = {selectedQuery}
          refetchSupportMaster = {refetchSupportMaster}
        />
      )}
    </div>
  );
};

export default SupportMaster;
