import React, { useState, useEffect }                                                         from "react";
import { NavLink }                                                                            from "react-router-dom";
import $                                                                                      from "jquery";
import Loading                                                                                from "../global/Loading";
import TutorialCategoryTable                                                                  from "./TutorialCategoryTable";
import * as helper                                                                            from "../global/Helper";
import Swal                                                                                   from "sweetalert2";
import { addVideoTutorialCategory, getVideoTutorialCategoryAll, updateVideoTutorialCategory } from "../api/applicationsAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

//Add Video Tutorial Category  Modal Starts ---->
export const AddTutorialCategory = (props) => {

    const {
      show,
      onDismissModal,
      selectedCategory,
      refetchVideoCategories
    } = props;
    
    //Tutorial category States
    const [btnLoading,          setBtnLoading]        = useState(false);
    const [tutorialCategory,    setTutorialCategory]  = useState(selectedCategory ? selectedCategory : { Active: "Y" });
  
    //TutorialCategoryDataHandler
    const TutorialCategoryDataHandler = (key, value) => {
      setTutorialCategory((data) => {
        return { ...data, [key]: value };
      });
    };
  
    // add video tutorial category handler
    const addTutorialCategoryHandler = async (e) => {
      e.preventDefault();
  
      var form = $("#add-tutorialcategory-form");
      form.validate().destroy();
  
      form.validate({
        rules: {
          CategoryName: {
            required: true,
          },
          Index: {
            required: true,
          },
        },
      });


      if (!form.valid()) {
        return;
      }
      
      
      try {
        helper.StartProcessing($("#save-btn"));
        if (selectedCategory) {
          await updateVideoTutorialCategory(tutorialCategory)
          Swal.fire({
            icon                : "success",
            titleText           : "video tutorial category updated Successfully",
            showConfirmButton   : false,
            timer               : 1500,
            toast               : true,
            position            :'bottom-end'
          });
        } else {
          await addVideoTutorialCategory(tutorialCategory);
          Swal.fire({
            icon                : "success",
            titleText           : "video tutorial category Added Successfully",
            showConfirmButton   : false,
            timer               : 1500,
          });
        }
        refetchVideoCategories();
        $("#add-tutorialcategory-modal").modal("hide");
      } catch (err) {
        Swal.fire({
          icon                    : "error",
          titleText               : "Error!",
          text                    : err.message,
          buttonsStyling          : false,
          confirmButtonClass      : "btn btn-brand",
        });
      }
    };
  
    useEffect(() => {
      $("#add-tutorialcategory-modal").modal({
        backdrop: "static",
      });
  
      $("#add-tutorialcategory-modal").on("hidden.bs.modal", function () {
        $(".modal").hasClass("show") && $("body").addClass("modal-open");
        onDismissModal();
      });
      $("#add-tutorialcategory-modal").modal("toggle");
    }, [show]);
  
    return (
      <div
        className="modal fade"
        id="add-tutorialcategory-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {`${selectedCategory ? "Edit" : "Add"} Video Tutorial Category`}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={btnLoading}>
              </button>
            </div>
            <div className="modal-body">
              <div className="author-container">
                <form id="add-tutorialcategory-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-5 col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            name="CategoryName"
                            id="CategoryName"
                            placeholder="Category Name"
                            value={tutorialCategory.CategoryName || ""}
                            onChange={({ target }) =>
                            TutorialCategoryDataHandler("CategoryName",helper.titleCase(target.value))
                            }
                          />
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <input
                            type="number"
                            className="form-control"
                            name="Index"
                            id="Index"
                            placeholder="Index"
                            value={tutorialCategory.Index || ""}
                            onChange={({ target }) =>
                            TutorialCategoryDataHandler("Index", target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-2 col-md-6 py-3">
                          <div className="d-flex align-item-center">
                            <span className="pt-2 mr-3">Active</span>
                            <span className="kt-switch kt-switch--icon">
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  defaultChecked={
                                    tutorialCategory.Active === "Y" ? "checked" : ""
                                  }
                                  onChange={() => {
                                    tutorialCategory.Active === "Y"
                                      ? TutorialCategoryDataHandler("Active", "N")
                                      : TutorialCategoryDataHandler("Active", "Y");
                                  }}
                                />
                                <span>{tutorialCategory.Active}</span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal"
                disabled={btnLoading}>
                Close
              </button>
  
              <button
                id="save-btn"
                onClick={ addTutorialCategoryHandler }
                type="button"
                className="btn btn-brand margin-0">
                {selectedCategory ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Add Video Tutorial Category Modal Ends ---->


  // Main Video Tutorial Categry Master Component
  const VideoTutorialCategory = (props) => {

    const [loading,                       setLoading]                         = useState(false);
    const [allVideoCategories,            setallVideoCategories]              = useState([]);
    const [showAddVideoTutorialCategory,  setshowAddVideoTutorialCategory]    = useState(false);
    const [selectedCategory,              setSelectedCategory]                = useState(null);
    const [refresh,                       setRefresh]                         = useState(false);
    
    const editVideoTutorialCategoryHandler = (tutorialCategory) => {
      setSelectedCategory(tutorialCategory);
      setshowAddVideoTutorialCategory(true);
    }

    const toggleVideoCategoryMasterStatus =  async (tutorialCategory) => {
      let a = tutorialCategory;
      if (a.Active === "Y") {
        a.Active = "N"
      } else {
        a.Active = "Y"
      }
      await updateVideoTutorialCategory(a);
    }
    
  useEffect(() => {
    if (!showAddVideoTutorialCategory) setSelectedCategory(null);
  }, [showAddVideoTutorialCategory]);
  

  useEffect(() => {
    let ignore = false;
    const fetchVideoTutorialCategoryAll = async () => {
      try {
        setLoading(true);
        const response = await getVideoTutorialCategoryAll();
        setallVideoCategories(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          titleText: "Error!",
          text: error.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchVideoTutorialCategoryAll();
    return () => {
      ignore = true;
    };
  }, [refresh]);

  useEffect(() => {
    props.title("Video Tutorial Category", "menuVideoTutorialCategory");
  }, []);

  const refetchVideoCategories = () => setRefresh((r) => !r);

    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
            <div className="kt-portlet__head-label">
              <nav className="file-nav navbar navbar-top">
                <NavLink activeClassName="top-nav-active" to="/video-tutorial-category">
                   Video Tutorial Category
                </NavLink>
              </nav>
            </div>
  
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <div className="kt-portlet__head-actions">
                  <button
                    className="btn btn-brand btn-icon-sm text-white"
                    onClick={() => setshowAddVideoTutorialCategory(true)}
                  >
                    <i className="la la-plus"></i>
                    New Category
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <div className="kt-portlet__body kt-portlet__body--fit">
            {loading ? (
              <Loading />
            ) : (
              <TutorialCategoryTable
              allVideoCategories={allVideoCategories}
              editVideoTutorialCategoryHandler={editVideoTutorialCategoryHandler}
              toggleVideoCategoryMasterStatus={toggleVideoCategoryMasterStatus}
              />
            )}
          </div>
        </div>
  
        {showAddVideoTutorialCategory && (
          <AddTutorialCategory
            show={showAddVideoTutorialCategory}
            onDismissModal={() => setshowAddVideoTutorialCategory(false)}
            selectedCategory={selectedCategory}
            refetchVideoCategories={refetchVideoCategories}
          />
        )}
      </div>
    );
  };
  
  export default VideoTutorialCategory;
