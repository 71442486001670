import React, { useState, useEffect }                                                               from "react";
import { NavLink }                                                                                  from "react-router-dom";
import Select                                                                                       from"react-select";
import $                                                                                            from "jquery";
import Loading                                                                                      from "../global/Loading";
import VideoTutorialTable                                                                           from "./VideoTutorialTable";
import * as helper                                                                                  from "../global/Helper";
import Swal                                                                                         from "sweetalert2";
import { addVideoTutorial, updateVideoTutorial, getVideoTutorialAll, getVideoTutorialCategoryAll }  from "../api/applicationsAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

//Add Video Tutorial Modal Starts ---->
export const AddVideoTutorial = (props) => {

  const {
    show,
    onDismissModal,
    selectedTutorial,refetchVideoTutorials
  } = props;

  const [btnLoading,             setBtnLoading]         = useState(false);
  const [allVideoCategories,     setallVideoCategories] = useState([]);
  const [videoTutorial,          setVideoTutorial]      = useState(selectedTutorial ? selectedTutorial:{ Active: "Y" });
  
  useEffect(() => { 
    let ignore = false;
    const fetchVideoCategory = async () => {
      const response = await getVideoTutorialCategoryAll();
      if(response.length > 0){
        response.map(c => { if(c.Active === "Y"){setallVideoCategories(d=>[...d,{label:c.CategoryName,value:c}])}});
      }
    }
    if(!ignore) fetchVideoCategory()
    return () => {
      ignore = true;
    }
  }, [])


  //VideoTutorialDataHandler
  const VideoTutorialDataHandler = (key, value) => {
    setVideoTutorial((data) => {
      return { ...data, [key]: value };
    });
  };

  // add video tutorial handler
  const addVideoTutorialHandler = async (e) => {
    e.preventDefault();

    var form = $("#add-videotutorial-form");
    form.validate().destroy();

    form.validate({
      rules: {
        Title: {
          required : true,  
        },
        Link: {
            required: true,
        },  
        CategoryName: {
        required: true,
        },
        Index: {
          required: true,
        },
        Description : {
            required: true,
        },
      },
    });
    if (!form.valid()) {
      return;
    }

    //check url link for correct format includes( ?v=)
    if(!videoTutorial.Link.includes("?v=")){
      return Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : "Incorrect Video Link",
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }
    //Video tutorial data save code goes here
    videoTutorial['Link'] = videoTutorial.Link.split('?v=')[1];
    try {
      helper.StartProcessing($("#save-btn"));
      if (selectedTutorial) {
        await updateVideoTutorial(videoTutorial);
        Swal.fire({
          icon                  : "success",
          titleText             : "Video Tutorial Updated Successfully",
          showConfirmButton     : false,
          timer                 : 1500,
          toast                 :true,
          position              :'bottom-end'
        });
      } else {
        await addVideoTutorial(videoTutorial);
        
        $("#add-videotutorial-modal").modal("hide");
        Swal.fire({
          icon                : "success",
          titleText           : "Video Tutorial Added Successfully",
          showConfirmButton   : false,
          timer               : 1500,
        });
      }
      refetchVideoTutorials();
      $("#add-videotutorial-modal").modal("hide");
    } catch (err) {
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }
  };

  useEffect(() => {
    if (videoTutorial.CategoryID)
     $("#CategoryName-error").hide();
    },[videoTutorial]);

  useEffect(() => {
    $("#add-videotutorial-modal").modal({
      backdrop: "static",
    });

    $("#add-videotutorial-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });
    $("#add-videotutorial-modal").modal("toggle");
  }, [show]);



  return (
    <div
      className="modal fade"
      id="add-videotutorial-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {`${selectedTutorial ? "Edit" : "Add"} Video Tutorial`}{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={btnLoading}>
            </button>
          </div>
            <div className="modal-body">
              <div className="">
                <form id="add-videotutorial-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Title"
                          id="Title"
                          placeholder="Title"
                          value={videoTutorial.Title || ""}
                          onChange={({ target }) =>
                          VideoTutorialDataHandler("Title", helper.titleCase(target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="url"
                          className="form-control"
                          name="Link"
                          id="Link"
                          placeholder="Link"
                          value={videoTutorial.Link || ""}
                          onChange={({ target }) =>
                            VideoTutorialDataHandler("Link", target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea 
                          type="text"
                          className="form-control"
                          name="Description"
                          id="Description"
                          placeholder="Description"
                          rows='2'
                          value={videoTutorial.Description || ""}
                          onChange={({target})=>VideoTutorialDataHandler('Description',target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-4">
                      <Select
                        options={allVideoCategories}
                        value={videoTutorial && allVideoCategories.find(c => c.value.ID === videoTutorial.CategoryID) }
                        onChange={(event)=>VideoTutorialDataHandler('CategoryID',event.value.ID)}
                        placeholder="Select Category"
                        id="CategoryName"
                        name="CategoryName"
                        className="CategoryName"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <input
                        type="number"
                        className="form-control"
                        name="Index"
                        id="Index"
                        placeholder="Index"
                        value={videoTutorial.Index || ""}
                        onChange={({ target }) =>
                        VideoTutorialDataHandler("Index", target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                className=""
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  videoTutorial.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  videoTutorial.Active === "N"
                                  ? VideoTutorialDataHandler("Active", "Y")
                                  : VideoTutorialDataHandler("Active", "N");
                                }}
                              />
                              <span> </span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>  
                </form> 
              </div>
            </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal"
              disabled={btnLoading}>
              Close
            </button>

            <button
              id="save-btn"
              onClick={addVideoTutorialHandler}
              type="button"
              className= "btn btn-brand margin-0">
              {selectedTutorial ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//Add Video Tutorial Modal Ends ---->


// Main Video Tutorial Component
const VideoTutorial = (props) => {
  
  const [loading,                      setLoading]              = useState(false);
  const [allVideoTutorials,            setAllVideoTutorials]    = useState([]);
  const [showAddVideoTuotrial,         setshowAddVideotutorial] = useState(false);
  const [selectedTutorial,             setSelectedTutorial]     = useState(null);
  const [refresh,                      setRefresh]              = useState(false);

  useEffect(() => {
    if (!showAddVideoTuotrial) setSelectedTutorial(null);
  }, [showAddVideoTuotrial]);


  useEffect(() => {
    let ignore = false;
    const fetchVideoTutorialAll = async () => {
      try {
        setLoading(true);
        const response = await getVideoTutorialAll();
        setAllVideoTutorials(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                    : "error",
          titleText               : "Error!",
          text                    : error.message,
          buttonsStyling          : false,
          confirmButtonClass      : "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchVideoTutorialAll();
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const editVideoTutorialHandler = (videoTutorial) => {
    setSelectedTutorial(videoTutorial);
    setshowAddVideotutorial(true);
  }

  const toggleVideoTutorialStatus =  async (videoTutorial) => {
    let a = videoTutorial;
    if (a.Active === "Y") {
      a.Active = "N"
    } else {
      a.Active = "Y"
    }
    await updateVideoTutorial(a);
  }

  const refetchVideoTutorials = () => setRefresh((r) => !r);

  useEffect(() => {
    props.title("Video Tutorial", "menuVideoTutorial");
  }, []);

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/video-tutorial">
                Video Tutorial
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setshowAddVideotutorial(true)}>
                  <i className="la la-plus"></i>
                  New Video Tutorial
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <VideoTutorialTable
              allVideoTutorials = {allVideoTutorials}
              editVideoTutorialHandler = {editVideoTutorialHandler}
              toggleVideoTutorialStatus = {toggleVideoTutorialStatus}
            />
          )}
        </div>
      </div>

      {showAddVideoTuotrial && (
        <AddVideoTutorial
          show={showAddVideoTuotrial}
          onDismissModal={() => setshowAddVideotutorial(false)}
          selectedTutorial = {selectedTutorial}
          refetchVideoTutorials={() => refetchVideoTutorials()}
        />
      )}
    </div>
  );
};

export default VideoTutorial;
