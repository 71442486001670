import React, { useState, useEffect } from "react";
import "./TutorialCategory.css";
import videoIcon from "../../assets/images/video-file.png";
import * as helper from "../global/Helper";

const VideoTutorialCategoryTable = (props) => {

  const {
    editVideoTutorialCategoryHandler,
    toggleVideoCategoryMasterStatus
  } = props;

  const [allVideoCategories, setallVideoCategories] = useState(props.allVideoCategories);
  const [showRemoveSearch, setshowRemoveSearch] = useState(false);
  
  const searchTutorialCategoryHandler = ({target}) => {
    if (target.value === "") {
      setshowRemoveSearch(false);
      return setallVideoCategories(props.allVideoCategories);
    }

    setshowRemoveSearch(true);
    const matchedTutorialCategories = props.allVideoCategories.filter((t)=>{

      let matchTutorial = `${t.CategoryName}`;
      let matchfound = 0;

      const searchArr = target.value.split(' ');
      searchArr.forEach(element => {
        matchfound += matchTutorial.toLocaleLowerCase().includes(element.toLowerCase()) ? 1 : 0;
      });
      return matchfound === searchArr.length;
    });
    setallVideoCategories(matchedTutorialCategories);
  }

  const clearSearchHandler = () =>{ 
    $("#generalSearch").val("");
    setshowRemoveSearch(false);
    return setallVideoCategories(props.allVideoCategories);
  }


  useEffect(() => {
    helper.SetScrollHeight();
  });

  return (
    <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Categories..."
                  id="generalSearch"
                  onChange={searchTutorialCategoryHandler}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
                {showRemoveSearch && (
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span onClick={clearSearchHandler}>
                          <i className="la la-close"></i>
                      </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {allVideoCategories.length > 0 ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="80%">
                      <span>Category</span>
                    </th>
                    <th className="kt-datatable__cell--right kt-datatable__cell padding-l50" width="20%">
                      <span className="margin-r40">Action</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {allVideoCategories.map((t, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row clickable">
                        <td
                          width="80%"
                          title={t.CategoryName}
                          className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__pic">
                            <img className="videoIcon" src={videoIcon} alt={t.Title} />
                            </div>          
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                                {t.CategoryName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell lucid"
                          width="20%">
                          <button
                            type="button"
                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                            onClick={()=>editVideoTutorialCategoryHandler(t,i)}>
                            <i className="flaticon2-edit"></i>  
                          </button>
                          <span className="kt-switch kt-switch--icon padding-l10 padding-t5 ">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  t.Active === "Y" ? "checked" : ""
                                }
                                onChange={()=>toggleVideoCategoryMasterStatus(t)}
                              />
                              <span></span>
                            </label>
                          </span> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <span className="empty-table-message">No Categories Found</span>
      )}
    </div>
  );
};

export default VideoTutorialCategoryTable;