import { axiosPost, axiosGet,axiosPatch } from "./axiosFunctions";
import {checkLoginToken} from "./common";

const addKnowledgeBaseUrl                   = "/api/applications/addKnowledgeBase";
const addKnowledgeBaseCategoriesUrl         = "/api/applications/addKnowledgeBaseCategory";
const addVideoTutorialUrl                   = "/api/applications/addVideoTutorial";
const addVideoTutorialCategoryUrl           = "/api/applications/addVideoTutorialCategory";
const addFAQURl                             = "/api/applications/addFAQ";
const getKnowledgeBaseAllUrl                = "/api/applications/getKnowledgeBaseAll";
const getKnowledgeBaseCategoriesAllUrl      = "/api/applications/getKnowledgeBaseCategoryAll";
const getVideoTutorialAllUrl                = "/api/applications/getVideoTutorialAll";
const getVideoTutorialCategoryAllUrl        = "/api/applications/getVideoTutorialCategoryAll";
const getFAQURl                             = "/api/applications/getFAQALL";
const checkIfKnowledgeBaseExistsUrl         = "/api/applications/checkIfKnowledgeBaseExists";
const checkIfknowledgeBaseCategoryExistsUrl = "/api/applications/checkIfknowledgeBaseCategoryExists";
const updateKnowledgeBaseUrl                = "/api/applications/updateKnowledgeBase";
const updateKnowledgeBaseCategoriesUrl      = "/api/applications/updateKnowledgeBaseCategory";
const updateVideoTutorialUrl                = "/api/applications/updateVideoTutorial";
const updateVideoTutorialCategoryUrl        = "/api/applications/updateVideoTutorialCategory";
const updateFAQURL                          = "/api/applications/updateFAQ";


//ADD OR POST  API's

export const addKnowledgeBase = async (KnowledgeBaseData) => {
  const token = checkLoginToken();
     if (!token) return;
     const response = await axiosPost(
      addKnowledgeBaseUrl,
      { KnowledgeBaseData },
      { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const addKnowledgeBaseCategories = async (knowledgeBaseCategory) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPost(
    addKnowledgeBaseCategoriesUrl,
    { knowledgeBaseCategory },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const addVideoTutorial = async (videoTutorial) => {
  const token = checkLoginToken();
     if (!token) return;
     const response = await axiosPost(
      addVideoTutorialUrl,
      { videoTutorial },
      { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const addVideoTutorialCategory = async (tutorialCategory) => {
    const token = checkLoginToken();
       if (!token) return;
       const response = await axiosPost(
        addVideoTutorialCategoryUrl,
        { tutorialCategory },
        { headers: { auth_token: `bearer ${token}` } }
    )
    return response;
};

export const addFAQ = async (faq) => {
    const token = checkLoginToken();
       if (!token) return;
       const response = await axiosPost(
        addFAQURl,
        { faq },
        { headers: { auth_token: `bearer ${token}` } }
    )
    return response;
};
     
//GET API's

export const getKnowledgeBaseAll = async () =>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getKnowledgeBaseAllUrl,
    {headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};

export const getFAQ = async () =>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getFAQURl,
    {headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};
// Check KB Exists
export const checkIfknowledgeBaseExists = async (Alias) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    `${checkIfKnowledgeBaseExistsUrl}/${Alias}`,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

// Check KBC Exists
export const checkIfknowledgeBaseCategoryExists = async (CategoryName) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    `${checkIfknowledgeBaseCategoryExistsUrl}/${CategoryName}`,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getKnowledgeBaseCategoriesAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getKnowledgeBaseCategoriesAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getVideoTutorialAll = async () =>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getVideoTutorialAllUrl,
    {headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};  

export const getVideoTutorialCategoryAll = async () => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosGet(
      getVideoTutorialCategoryAllUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

//UPDATE ||  PATCH API's

export const  updateKnowledgeBase = async (KnowledgeBaseData) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateKnowledgeBaseUrl,
    { KnowledgeBaseData },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const updateKnowledgeBaseCategories = async (knowledgeBaseCategory) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateKnowledgeBaseCategoriesUrl,
    { knowledgeBaseCategory },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const  updateVideoTutorial = async (videoTutorial) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateVideoTutorialUrl,
    { videoTutorial },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

export const updateVideoTutorialCategory = async (tutorialCategory) => {
    const token = checkLoginToken();
      if (!token) return;
      const response = await axiosPatch(
        updateVideoTutorialCategoryUrl,
        { tutorialCategory },
        { headers: { auth_token: `bearer ${token}` } }
    )
    return response;
};

export const updateFAQ = async (faq) => {
    const token = checkLoginToken();
      if (!token) return;
      const response = await axiosPatch(
        updateFAQURL,
        { faq },
        { headers: { auth_token: `bearer ${token}` } }
    )
    return response;
};
