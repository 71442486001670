import React, { useState, useEffect } from "react";
import * as helper                    from "../global/Helper";
import moment                         from "moment";
import RightSideDetails from "../global/RightSideDetails";
import "./supportmaster.css"; 


const SupportTable = (props) => {

  const [AllQueries,    setAllQueries]    = useState( props.AllQueries);
  const [selectedQuery, setSelectedQuery] = useState(false);
  const [showRemoveSearch, setshowRemoveSearch] = useState(false);

  const searchSupportHandler =({target})=>{
    if(target.value === ""){
      setshowRemoveSearch(false);
      return setAllQueries(props.AllQueries);
    }
    setshowRemoveSearch(true);
    const matchedData = props.AllQueries.filter(s => {
      let matchString = `  ${s.Name} ${s.Email} ${s.Ticket} ${s.Query} ${s.ContactNo} ${moment(s.CreatedDate).format("DD/MM/YYYY hh:mm A")} ${s.Status}`;
      let matchFound = 0;

      const searchArr = target.value.split(' ');
      searchArr.forEach(term => {
        matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
      });

      return matchFound === searchArr.length;
    })
    setAllQueries(matchedData);
  }

  const clearSearchHandler = () =>{ 
    $("#generalSearch").val("");
    setshowRemoveSearch(false);
    return setAllQueries(props.AllQueries);
  }

  const closeDrawerHandler = () => {
    setTimeout(() => {
      setSelectedQuery(null);
    }, 180);
  };


    useEffect(()=>{
      helper.SetScrollHeight();
    });

    return (
        <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Support..."
                  id="generalSearch"
                  onChange={searchSupportHandler}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
                {showRemoveSearch && (
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span onClick={clearSearchHandler}>
                          <i className="la la-close"></i>
                      </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {AllQueries.length > 0 ? (
        <div className="row">
          {selectedQuery && (
            <RightSideDetails
              onCloseDrawer={closeDrawerHandler}
              show={!!selectedQuery}
              title="Support Details">
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      <div className="kt-widget__pic kt-widget__pic--brand kt-font-boldest">
                        {selectedQuery.Name.slice(0, 1)}
                      </div>
                      <div className="kt-widget__content">
                        <div className="row align-items-center">
                          <div className="col-md-9">
                            <div className="kt-widget__head">
                              <span className="kt-widget__username">
                                {selectedQuery.Name}
                              </span>
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="fa fa-clock"></i>
                                {`${moment(selectedQuery.CreatedDate).format('DD/MM/YYYY')} - ${moment(selectedQuery.CreatedDate).format("hh:mm A")}`}
                              </span>
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="flaticon2-new-email"></i>
                                {selectedQuery.Email}
                              </span>
                              {selectedQuery.ContactNo && 
                              <span className="padding-r10">
                                <i className="flaticon2-phone"></i>
                                {selectedQuery.ContactNo}
                              </span>}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className = {`block-badge display-inline mr-1 badge badge-${selectedQuery.StatusID == 1 ? 'warning' : (selectedQuery.StatusID == 2 ? 'info' : 'success')} `}>
                              <span>{selectedQuery.Status}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <hr/>
                    <div className="kt-title">
                      <span className="margin-l10">{selectedQuery.Query}</span>
                    </div>
                    <div className="margin-l10" >
                      <span className="kt-desc ">{selectedQuery.Message}</span>
                    </div>  
                  </div>
                </div>
              </div>
            </RightSideDetails>
          )}
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="25%">
                      <span>Name</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="15%">
                      <span>Ticket</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="15%">
                      <span>Query</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="12%">
                      <span>Contact</span>
                    </th>
                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                      <span>Date</span>
                    </th>
                    <th className="kt-datatable__cell--center kt-datatable__cell" width="15%">
                      <span>Status</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {AllQueries.map((s, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row clickable"
                        onClick={()=>setSelectedQuery(s)}>
                             <td
                          width="25%"
                          title={s.Name}
                          className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                              <div className="kt-badge kt-badge--xl kt-badge--success">
                               {s.Name.slice(0, 1)}
                              </div>
                            </div>
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                               {s.Name}
                              </span>
                              {s.Email && (
                                  <span
                                    className="kt-user-card-v2__desc text-clip" 
                                    title={s.Email}>
                                    {s.Email.toLowerCase()}
                                  </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td 
                          width="15%" 
                          title={s.Ticket} 
                          className = " kt-title kt-datatable__cell text-clip">
                          <span>{s.Ticket}</span>
                        </td>
                        <td
                          width="15%"
                          title = {s.Query}
                          className = "kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                                {s.Query}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="12%"
                          title={s.ContactNo}>
                          <span> {s.ContactNo}</span>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="15%"
                          title= {`${moment(s.CreatedDate).format('DD/MM/YYYY')} - ${moment(s.CreatedDate).format("hh:mm A")}`}>
                          <span> {`${moment(s.CreatedDate).format('DD/MM/YYYY')} - ${moment(s.CreatedDate).format("hh:mm A")}`}</span>
                        </td>
                        <td 
                          width="15%" 
                          title={s.Status} 
                          className="kt-datatable__cell"
                          >
                          <div className={`block-badge mr-1 badge badge-${s.StatusID == 1 ? 'warning' : (s.StatusID == 2 ? 'info' : 'success')}`}>
                              <span>{s.Status}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <span className="empty-table-message">No Support Found</span>
      )}
    </div>
  );

    
}

export default SupportTable;

